/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Error from "../../components/404";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Logo from "../../images/logo.png";
import ProfileShow from "../../components/ProfileShow";

const ProfileShowPage = () => {
  return (
    <Fragment>
      {/* <Navbar Logo={Logo} /> */}
      {/* <PageTitle pageTitle={"404"} pagesub={"404"} /> */}
      <ProfileShow />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProfileShowPage;
