/* eslint-disable no-unused-vars */
// eslint-disable-next-line react-hooks/exhaustive-deps
/* eslint-disable */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useState } from "react";
import { BASE_URL, BUS_TICKET_SALES_FILTER_BY } from "../../constant/constants";
import {
  getTicketpassengers,
  getBookinSummary,
} from "../../dataSlice/dataSlice";

const Commission = ({ toggle }) => {
  const dispatch = useDispatch();
  const [datas, setData] = useState([]);
  const [filterData, setFilterData] = useState({});

  const user_id = localStorage.getItem("user_id");
  const buses = useSelector((state) => state.data?.bus);
  const passengers = useSelector((state) => state.data?.ticketpassengers);
  const filteredPassengers = passengers.filter(
    (passenger) => passenger.member == user_id
  );

  const bookingSummary = useSelector((state) => state.data?.allBooking);

  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getTicketpassengers());
    const user_id = localStorage.getItem("user_id");
  }, [dispatch, user_id]);

  useEffect(() => {
    dispatch(getBookinSummary(filterData));
  }, [toggle, filterData]);

  const CalculateCommission = (data) => {
    return data
      ?.reduce((total, book) => total + (book.total_discount_amount || 0), 0)
      .toFixed(2);
  };
  console.log("bookingSummary", bookingSummary);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the form submission logic
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  // Function to generate time options with 30-minute intervals
  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour >= 12 ? "PM" : "AM";
        const formattedMinute = minute === 0 ? "00" : minute;
        const timeLabel = `${formattedHour}:${formattedMinute} ${period}`;
        const timeValue = `${hour
          .toString()
          .padStart(2, "0")}:${formattedMinute}`;
        timeOptions.push({ label: timeLabel, value: timeValue });
      }
    }
    return timeOptions;
  };
  const timeOptions = generateTimeOptions();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  return (
    <div className="mx-3">
      <div className="container mt-4">
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row mx-0">
              <div className="form-group col-md-2">
                <label>Date From:</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_after"
                  value={filterData.date_after}
                  onChange={handleChange}
                />
              </div>{" "}
              <div className="form-group col-md-2">
                <label>Date To:</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_before"
                  value={filterData.date_before}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Bus:</label>
                <select
                  className="form-control"
                  name="bus"
                  value={filterData.bus || filterData?.bus}
                  onChange={handleChange}
                >
                  <option value="">Select bus</option>{" "}
                  <option value="">All</option>{" "}
                  {/* Default "Select bus" option */}
                  {buses?.map((bus) => (
                    <option key={bus?.id} value={bus?.id}>
                      {bus?.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </div>
          </div>
        </form>
      </div>
      <h3 className="text-center mt-5">Commission</h3>
      <div
        className="table-responsive rounded"
        style={{ height: "500px", backgroundColor: "white" }}
      >
        <table className="table table-striped">
          <thead
            style={{ position: "sticky", top: 0 }}
            className="bg-dark text-white"
          >
            <tr style={{ height: "2rem", whiteSpace: "nowrap" }}>
              <th scope="col">SL.</th>
              <th scope="col">Deprature Date</th>
              <th scope="col">Agent Name</th>
              <th scope="col">Bus</th>
              <th scope="col">Seat No</th>
              <th scope="col">Commission Type</th>
              <th scope="col">Ticket Status</th>
              <th scope="col">Ticket Price</th>
              <th scope="col">Commission Amount</th>
            </tr>
          </thead>
          <tbody>
            {bookingSummary?.map((book, key) => (
              <tr key={key} style={{ height: "2rem", whiteSpace: "nowrap" }}>
                <th>{key + 1}</th>
                <th>{book.date}</th>
                <th>{book?.member?.first_name || "N/A"}</th>
                <th>{book?.bus.name || "N/A"}</th>
                <th>{book?.seat_no}</th>
                <th>{`${book?.discount_type} ${
                  book?.discount_value
                    ? ` (${book.discount_value})`
                    : ` (${book?.discount_percent}`
                }%)`}</th>
                <th>{book?.status}</th>
                <th>{book?.total_cost}</th>
                <th>{book?.total_discount_amount}</th>
              </tr>
            ))}
          </tbody>
          {bookingSummary.length !== 0 && (
            <tfoot>
              <tr>
                <td
                  colSpan="8"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total Commission:
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {Number(
                    bookingSummary?.reduce(
                      (total, book) =>
                        total + (parseInt(book.total_discount_amount) || 0),
                      0
                    )
                  ).toFixed(2)}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};
export default Commission;
