/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Scrollbar from "../../components/scrollbar";
import CauseTabs from "./alltab";
import CauseSidebar from "./sidebar";
import Footer from "../../components/footer";
import { useParams } from "react-router-dom";
import { BASE_URL, GET_CAUSE_BY_ID } from "../../constant/constants";
import Loading from "../../components/Loading";

const CauseSinglePage = (props) => {
  const { id } = useParams();

  const [causes, setCauses] = useState();

  //for content images
  useEffect(() => {
    fetch(`${GET_CAUSE_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCauses(data);
      })
      .catch(() => {});
  }, [id, props.id]);
  return (
    <div>
      {causes?.image ? (
        <Fragment>
          <PageTitle pageTitle={causes?.name} />
          <div className='wpo-case-details-area section-padding'>
            <div className='container'>
              <div className='row'>
                <div className='col col-lg-12'>
                  <div className='wpo-case-details-wrap'>
                    <div className='wpo-case-details-img'>
                      <img src={`${BASE_URL}${causes?.image}`} alt='' />
                    </div>
                    <CauseTabs />
                  </div>
                </div>
                {/* <CauseSidebar /> */}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          <Scrollbar />
        </Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default CauseSinglePage;
