import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import { Interweave } from "interweave";
import { Carousel } from "react-bootstrap-v5";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_MENU_ITEM_BY_ID,
} from "../../constant/constants";
import Loading from "../../components/Loading";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const LiveProgramSinglePage = (props) => {
  const { id } = useParams();
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  const [menuContent, setMenuContent] = useState({});

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});

    fetch(`${GET_MENU_ITEM_BY_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setMenuContent(data);
      })
      .catch(() => {});
    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id]);

  const newContents = items(contents);

  return (
    <div>
      {menuContent?.image ? (
        <Fragment>
          {/* <Navbar2 Logo={menuContent?.image} /> */}
          <PageTitle
            pageTitle={menuContent?.name}
            pagesub={menuContent?.name}
          />
          <section className='wpo-project-single-section section-padding'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-10 offset-lg-1'>
                  <div className='wpo-project-single-wrap'>
                    <div className='wpo-project-single-content'>
                      <div className='container-fluid'>
                        <img
                          style={{
                            borderRadius: "2%",
                            display: "block",
                            maxHeight: "fit-content",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          loading='lazy'
                          className='d-block w-100 mx-auto text-center rounded'
                          src={`${BASE_URL}${menuContent?.image}`}
                          alt=''
                        />
                      </div>
                      <div className='wpo-project-single-content-des'>
                        <div className='wpo-project-single-content-des-wrap'>
                          <div className='wpo-project-single-content-des-left'>
                            <h2>{menuContent?.name}</h2>
                            <Interweave
                              allowAttributes
                              allowElements
                              disableLineBreaks={true}
                              content={menuContent?.note}
                            />
                          </div>
                          <div className='wpo-project-single-content-des-right'>
                            {newContents.map((service, index) =>
                              Object.entries(service).map(
                                ([contentKey, contentValue]) =>
                                  contentKey === "Duration" && (
                                    <div className=' col-12' key={index}>
                                      <h2>{contentKey}</h2>

                                      <Interweave
                                        allowAttributes
                                        allowElements
                                        disableLineBreaks={true}
                                        content={contentValue}
                                      />
                                    </div>
                                  )
                              )
                            )}
                          </div>
                        </div>

                        <div>
                          <Carousel fade>
                            {Object.entries(
                              contentsImage ? contentsImage : {}
                            ).map(
                              ([key, value]) =>
                                key === "Program Image" &&
                                value.map((img) => (
                                  <Carousel.Item>
                                    <img
                                      className='d-block w-100 mx-auto text-center rounded'
                                      // style={{ objectFit: "cover", opacity: "0.7" }}
                                      // style={{ filter: "brightness(70%)" }}
                                      height={400}
                                      loading='lazy'
                                      src={`${BASE_URL}/media/${img}`}
                                      alt='First slide'
                                    />
                                  </Carousel.Item>
                                ))
                            )}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className='wpo-solutions-section'>
                      {/* <h2>Best Benefits of Project</h2> */}
                      <div className='row'>
                        {newContents.map((service, index) =>
                          Object.entries(service).map(
                            ([contentKey, contentValue]) =>
                              contentKey === "Program Benefits" && (
                                <div className=' col-12' key={index}>
                                  <h2>{contentKey}</h2>

                                  <Interweave
                                    allowAttributes
                                    allowElements
                                    disableLineBreaks={true}
                                    content={contentValue}
                                  />
                                </div>
                              )
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Scrollbar />
        </Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
};
export default LiveProgramSinglePage;
