/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import erimg from "../../images/error-404.png";
import Support from "../Dashboard/Support";

const SupportTab = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <Support></Support>{" "}
    </div>
  );
};

export default SupportTab;
