import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSupportTicketDetail,
  saveTicketDetail,
} from "../../dataSlice/dataSlice";
import { BASE_URL } from "../../constant/constants";
import moment from "moment/moment";

const SupportChat = (props) => {
  const id = props?.id;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState();
  const user_id = localStorage.getItem("user_id");
  const supportTicketDetails = useSelector(
    (state) => state.data.supportTicketDetail
  );

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    dispatch(getSupportTicketDetail(id));
  }, [dispatch, id]);

  const SubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);

    const TicketDetail = {
      message: data?.message,
      file: imageFile,
      ticket: id,
      customer: user_id,
    };
    dispatch(saveTicketDetail(TicketDetail)).then((res) => {
      if (res.payload) {
        document.forms["formName"].reset();

        dispatch(getSupportTicketDetail(id));
      }
    });
  };

  function updateImageData(event) {
    setImageFile(event.target.files[0]);
  }
  return (
    <div className='wpo-donation-page-area section-padding'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-md-10 mt-5 border shadow p-3 mb-5 bg-white rounded'>
            {supportTicketDetails.map((supportTicketDetail) => (
              <div
                style={{
                  textAlign:
                    supportTicketDetail?.customer &&
                    supportTicketDetail?.customer.id === user_id &&
                    "end",
                }}
                className='p-3'
              >
                <div
                  className='row'
                  style={{
                    flexDirection:
                      supportTicketDetail?.customer &&
                      supportTicketDetail?.customer.id === user_id &&
                      "row-reverse",
                  }}
                >
                  <div className='col-md-1'>
                    {" "}
                    {supportTicketDetail?.admin ? (
                      <img
                        src={`${BASE_URL}${supportTicketDetail?.admin?.image}`}
                        alt=''
                        width='50'
                        height='50'
                        loading='lazy'
                        className='rounded-circle text-right m-2'
                      />
                    ) : (
                      <img
                        src={`${BASE_URL}${supportTicketDetail?.customer?.image}`}
                        alt=''
                        width='50'
                        height='50'
                        loading='lazy'
                        className='rounded-circle text-right m-2'
                      />
                    )}
                  </div>
                  <div className='col-md-9'>
                    <h5>
                      {supportTicketDetail?.customer
                        ? supportTicketDetail?.customer?.first_name
                        : supportTicketDetail?.admin?.first_name}
                    </h5>
                    <p>{supportTicketDetail?.message}</p>
                    <div>
                      <img
                        src={`${BASE_URL}${supportTicketDetail?.file}`}
                        alt=''
                        // width='150'
                        // height='250'
                        className='w-50'
                        loading='lazy'
                        style={{
                          display: supportTicketDetail?.file
                            ? "inline"
                            : "none",
                        }}
                      />
                    </div>
                    <small>
                      {supportTicketDetail?.created_at &&
                        moment(
                          new Date(supportTicketDetail?.created_at)
                        ).format(" h:m a")}{" "}
                    </small>
                  </div>
                </div>
              </div>
            ))}
            <div id='Donations' className='tab-pane'>
              <form onSubmit={SubmitHandler} name='formName'>
                <div className='wpo-donations-details'>
                  <div className='row'>
                    <div className='col-lg-12 col-12 form-group'>
                      <textarea
                        type='text'
                        className='form-control'
                        name='message'
                        id='message'
                        onChange={updateData}
                        placeholder='Message'
                      />
                    </div>

                    <div className='col-lg-12 col-12 form-group'>
                      <input
                        type='file'
                        className='form-control'
                        name='image'
                        id='image'
                        onChange={updateImageData}
                        placeholder='Image'
                      />
                    </div>
                  </div>
                </div>

                <div className='submit-area'>
                  <button type='submit' className='theme-btn submit-btn'>
                    Post{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportChat;
