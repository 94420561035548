import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../constant/constants";
import { getMember } from "../../dataSlice/dataSlice";
import "./style.css";
const UserProfile = () => {
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();

  const member = useSelector((state) => state?.data?.member);
  useEffect(() => {
    dispatch(getMember(user_id));
  }, [dispatch, user_id]);
  return (
    <>
      <div className='container mt-5'>
        <div className='main-body px-md-5 mx-md-5'>
          <div className='row'>
            <div className='col-md-1 col-sm-0'></div>
            <div className='col-md-10 col-sm-12'>
              <div className='card shadow-lg p-3 mb-5 bg-white rounded'>
                <div className='card-body mx-md-3 px-md-5'>
                  <div className='row mb-12 col-sm-12 mx-auto'>
                    <img
                      src={`${BASE_URL}${member?.image}`}
                      style={{ width: "150px", height: "150px" }}
                      alt='Not found'
                      loading='lazy'
                      className='rounded-circle pb-4 ml-5 mx-auto'
                    />
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Name</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.first_name}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Username</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.username}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Refer Id</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.refer_id}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Refered By</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>
                        {member?.head_user?.first_name}-
                        {member?.head_user?.refer_id}-
                        {member?.head_user?.primary_phone}
                      </h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Email</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.email}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Phone Number</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.primary_phone}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Father Name</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.father_name}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Mother Name</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.mother_name}</h6>
                    </div>
                  </div>
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>NID</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>{member?.nid}</h6>
                    </div>
                  </div>
                  {/* <div className="row mb-3 border-bottom ">
                    <div className="col-sm-3 ">
                      <h6 className="mb-0 pb-2">Date of Birth</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <h6 className="mb-0 pb-2">{member?.date_of_birth}</h6>
                    </div>
                  </div> */}
                  <div className='row mb-3 border-bottom '>
                    <div className='col-sm-3 '>
                      <h6 className='mb-0 pb-2'>Address</h6>
                    </div>
                    <div className='col-sm-9 text-secondary'>
                      <h6 className='mb-0 pb-2'>
                        {member?.street_address_one}
                      </h6>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-12 text-light text-center'>
                      <button className='btn btn-success text-white'>
                        <Link to='/profile'>
                          {" "}
                          <span style={{ color: "white" }}>Update</span>
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
