/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Scrollbar from "../../components/scrollbar";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import countryCodes from "../../api/countrycodes";
import { CHECK_PRIMARY_PHONE, CHECK_REFFER_ID } from "../../constant/constants";
import { getMember, updateMember } from "../../dataSlice/dataSlice";
import { useDispatch, useSelector } from "react-redux";

const ProfilePage = () => {
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();

  const member = useSelector((state) => state.data.member);
  const [username, setUsername] = useState(member?.username);
  const [firstName, setFirstName] = useState(member?.first_name);
  const [email, setEmail] = useState(member?.email);
  const [headUser, setHeadUSer] = useState(member?.head_user?.refer_id);

  useEffect(() => {
    dispatch(getMember(user_id));
  }, [dispatch, user_id]);

  const [data, setData] = useState({});

  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [imageFile, setImageFile] = useState();

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      country_code: selectedCountry.value,
      country: selectedCountry.label,
    });
  };

  const notify = () =>
    toast.success(`Successfully updated.`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const history = useHistory();

  const SubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);

    const member = {
      first_name: firstName,
      email: email,
      father_name: data?.father_name,
      mother_name: data?.mother_name,
      username: username,
      head_user: headUser?.toUpperCase(),
      address: data?.address,
      nid: data?.nid_no,
      country_code1: selectedCountry.value,
      primary_phone: `${data.primary_phone}`,
      image: imageFile,
    };
    dispatch(updateMember(member)).then((res) => {
      if (res.payload) {
        notify();
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      }
    });
  };

  // Check Duplicate Phone Number
  useEffect(() => {
    fetch(
      `${CHECK_PRIMARY_PHONE}?primary_phone=${data?.country_code}${data?.primary_phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.primary_phone_exists) {
          toast.error(`Phone Number Already Exists,Please Provide New Number`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          const getValue = document.getElementById("primary_phone");
          getValue.value = "";
        }
      });
  }, [data.country_code, data.primary_phone]);

  // Check existance Reffer Id
  useEffect(() => {
    if (data?.head_user?.length == 7) {
      fetch(`${CHECK_REFFER_ID}?refer_id=${data?.head_user?.toUpperCase()}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.refer_id_exists) {
            toast.error(
              `Invalid Refer Id,Please Provide the Correct Refer Id`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            const getValue = document.getElementById("head_user");
            setTimeout(() => {
              getValue.value = "";
            }, 1000);
            // getValue.value = "";
          }
        });
    }
  }, [data.head_user]);

  const handleCountryChange = (event) => {
    setSelectedCountry(
      countryCodes.find((country) => country.label === event.target.value)
    );
  };

  function updateImageData(event) {
    setImageFile(event.target.files[0]);
  }

  return (
    <Fragment>
      <div className='wpo-donation-page-area section-padding'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8' style={{ marginTop: "80px" }}>
              <div className='row justify-content-center'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    <div className='wpo-section-title'>
                      <h3 className='p-3 border mt-4'>
                        Hi {member?.first_name} ! update Your Info{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Donations' className='tab-pane'>
                <form onSubmit={SubmitHandler}>
                  <div className='wpo-donations-details'>
                    <div className='row'>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='first_name'
                          // onChange={updateData}
                          onChange={(e) => setFirstName(e.target.value)}
                          defaultValue={member?.first_name}
                          id='first_name'
                          placeholder=' Name'
                        />
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='username'
                          onChange={(e) => setUsername(e.target.value)}
                          defaultValue={member?.username}
                          id='username'
                          placeholder=' User Name'
                        />
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix'>
                        <input
                          type='email'
                          className='form-control'
                          name='email'
                          id='email'
                          onChange={(e) => setEmail(e.target.value)}
                          defaultValue={member?.email}
                          placeholder='Email'
                        />
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='head_user'
                          // onChange={updateData}
                          onChange={(e) => setHeadUSer(e.target.value)}
                          defaultValue={member?.head_user?.refer_id}
                          id='head_user'
                          placeholder=' Refer Id'
                        />
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='father_name'
                          onChange={updateData}
                          defaultValue={member?.father_name}
                          id='father_name'
                          placeholder='Father Name'
                        />
                      </div>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='mother_name'
                          onChange={updateData}
                          defaultValue={member?.mother_name}
                          id='mother_name'
                          placeholder='Mother Name'
                        />
                      </div>

                      <div className='col-lg-12 col-12 col-sm-12 form-group d-md-flex '>
                        <div className='col-md-4 col-12 col-sm-2 form-group'>
                          <select
                            className='form-control'
                            style={{ padding: "12px" }}
                            id='country'
                            value={selectedCountry.label}
                            onChange={handleCountryChange}>
                            {countryCodes.map((country) => (
                              <option key={country.label} value={country.label}>
                                {country.label} ({country.code})
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-md-2 col-sm-2 col-12 form-group'>
                          {" "}
                          <input
                            type='text'
                            className='form-control'
                            name='country_code'
                            id='country_code'
                            onChange={updateData}
                            value={selectedCountry.value}
                          />
                        </div>
                        <div className='col-md-6 col-12 col-sm-8 form-group'>
                          {" "}
                          <input
                            type='text'
                            className='form-control'
                            name='primary_phone'
                            id='primary_phone'
                            onChange={updateData}
                            placeholder='1912345678'
                            defaultValue={member?.primary_phone}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='nid_no'
                          onChange={updateData}
                          defaultValue={member?.nid}
                          id='nid_no'
                          placeholder='NID No'
                        />
                      </div>

                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='text'
                          className='form-control'
                          name='address'
                          id='address'
                          onChange={updateData}
                          defaultValue={member?.street_address_one}
                          placeholder='Permanent Address'
                        />
                      </div>

                      <div className='col-lg-12 col-12 form-group'>
                        <input
                          type='file'
                          className='form-control'
                          name='image'
                          id='image'
                          onChange={updateImageData}
                          placeholder='Image'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='submit-area'>
                    <button type='submit' className='theme-btn submit-btn'>
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <Scrollbar />
    </Fragment>
  );
};
export default ProfilePage;
