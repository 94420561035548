/* eslint-disable no-unused-vars */
import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_MENUS_ALL_NESTED,
} from "../../constant/constants";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const PreviousProgramSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { id } = useParams();
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  const [homeId, setHomeId] = useState();
  const [subMenu, setSubMenu] = useState([]);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "Program" ? setSubMenu(e.children) : null
        );
      })
      .catch(() => {});
  }, [id]);
  useEffect(() => {
    const ProjectList = subMenu.find(
      (element) => element.name === "Previous Program"
    );
    setProjectList(ProjectList?.children);
  }, [subMenu]);

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});

    fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  const newContents = items(contents);

  return (
    <section className={`wpo-project-section section-padding ${props.pbClass}`}>
      <div className='container-fluid'>
        <div className='sortable-gallery'>
          <div className='row'>
            {projectList?.map((service, index) => (
              <div className='col-md-6 col-sm-12'>
                <div className='grid' key={index}>
                  <div
                    className='img-holder'
                    style={{ width: "100%", height: "550" }}
                  >
                    <img
                      src={`${BASE_URL}${service?.image} `}
                      alt=''
                      width='100%'
                      height='550'
                      loading='lazy'
                      className='rounded border border-warning p-2  col-md-12'
                    />

                    <div className='hover-content'>
                      <Link
                        onClick={ClickHandler}
                        className='plus'
                        to={`/live Program-single/${service?.id}/${service?.name}`}
                      >
                        <i className='ti-plus'></i>
                      </Link>
                      <h4>
                        <Link
                          onClick={ClickHandler}
                          to={`/live Program-single/${service?.id}/${service?.name}`}
                        >
                          {service?.name}
                        </Link>
                      </h4>
                      <p>
                        <Interweave
                          allowAttributes
                          allowElements
                          disableLineBreaks={true}
                          content={`${service?.note.slice(
                            0,
                            300
                          )}   ...see more`}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreviousProgramSection;
