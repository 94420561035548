import React, { Fragment } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Dashboard from "../../components/Dashboard";

const DashboardPage = () => {
  return (
    <Fragment>
      {/* <PageTitle pageTitle={"Dashboard"} pagesub={"Dashboard"} /> */}
      <Dashboard />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DashboardPage;
