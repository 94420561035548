import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Homepage from "../HomePage";

import { useDispatch } from "react-redux";
import Navbar2 from "../../components/Navbar2";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import { getAllmenus, getSiteSetting } from "../../dataSlice/dataSlice";
import AboutPage from "../AboutPage";

import CausePage from "../CausePage";
import CauseSinglePage from "../CauseSinglePage";
import ContactPage from "../ContactPage";
import DashboardPage from "../DashboardPage";
import DonatePage from "../DonatePage";
import ErrorPage from "../ErrorPage";

import ForgotPassword from "../ForgotPassword";
import FormPage from "../FormPage";
import GalleryPage from "../GalleryPage";
import LiveProgramPage from "../LiveProgramPage";
import LiveProgramSinglePage from "../LiveProgramSinglePage";
import LoginPage from "../LoginPage";
import PreviousProgramPage from "../PreviousProgramPage";
import ProfilePage from "../ProfilePage";
import SignUpPage from "../SignUpPage";
import ProfileShowPage from "../ProfileShowPage";
import SupportPage from "../SupportPage";

const AllRoute = ({ logo }) => {
  const [homeId, setHomeId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
      })
      .catch(() => {});
    dispatch(getAllmenus());
    dispatch(getSiteSetting());
  }, [dispatch]);

  return (
    <div className='App'>
      <Router>
        <Navbar2
          Logo={logo}
          id={homeId}
          hclass={"wpo-header-style-4 text-nowrap"}
        />

        <Switch>
          {localStorage.getItem("jwt_access_token") ? (
            <Route exact path='/' component={DashboardPage} />
          ) : (
            <Route exact path='/' component={LoginPage} />
          )}{" "}
          {/* <Route exact path='/' component={LoginPage} /> */}
          <Route path='/home/:id' component={Homepage} />
          <Route path='/about/:id' component={AboutPage} />
          <Route path='/gallery/:id' component={GalleryPage} />
          <Route path='/membership/:id' component={FormPage} />
          <Route path='/dashboard' component={DashboardPage} />
          <Route path='/cause/:id' component={CausePage} />
          <Route path='/cause-single/:id' component={CauseSinglePage} />
          <Route path='/live Program/:id' component={LiveProgramPage} />
          <Route path='/previous Program/:id' component={PreviousProgramPage} />
          <Route path='/program/:id' component={LiveProgramPage} />
          <Route
            path='/live Program-single/:id/:projectName'
            component={LiveProgramSinglePage}
          />
          <Route
            path='/Previous Program-single/:id/:projectName'
            component={LiveProgramSinglePage}
          />
          <Route path='/donate/:id/:causeName' component={DonatePage} />
          <Route path='/404' component={ErrorPage} />
          <Route path='/contact' component={ContactPage} />
          <Route path='/login' component={LoginPage} />
          <Route path='/profile' component={ProfilePage} />
          <Route path='/support' component={SupportPage} />
          <Route path='/ProfileShowPage' component={ProfileShowPage} />
          <Route path='/register' component={SignUpPage} />
          <Route path='/forgot-password' component={ForgotPassword} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
