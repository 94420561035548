import React from "react";

const AgeRow = ({
  title,
  ageRange,
  count,
  index,
  total,
  maxCount,
  onCountChange,
}) => {
  const handleIncrement = () => {
    if (total < maxCount) {
      onCountChange(index, 1);
    }
  };

  const handleDecrement = () => {
    if (count > 0) {
      onCountChange(index, -1);
    }
  };

  return (
    <tr className="my-4">
      <td>
        <div className="Title d-flex flex-column">
          <p>{title}</p>
          <span style={{ fontSize: "10px" }}>Age {ageRange}</span>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center buttonDiv">
          <button
            type="button"
            className={`MinusButton ${count === 0 ? "disabledBg" : ""}`}
            onClick={handleDecrement}
            disabled={count === 0}
          >
            <svg
              width="14"
              height="2"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="14" height="2" rx="1" fill="#1D57C7"></rect>
            </svg>
          </button>
          <span className="Participant" data-testid="total-selected">
            {count}
          </span>
          <button
            type="button"
            className={`PlusButton ${total >= maxCount ? "disabledBg" : ""}`}
            onClick={handleIncrement}
            data-testid="plus-button"
            disabled={total >= maxCount}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-testid="plus-icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C6.44772 0 6 0.447716 6 1V6H1C0.447716 6 0 6.44771 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1C8 0.447715 7.55228 0 7 0Z"
                fill="#000"
              ></path>
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default AgeRow;
