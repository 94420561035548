import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/hero";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import AboutS2 from "../../components/AboutS2";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";

const HomePage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <Hero />
      {/* <MadrasahName id={homeId} /> */}
      {/* <OurLivePrograms /> */}
      <AboutS2 id={homeId} />
      {/* <CauseSection /> */}
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
