/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import "./style.css";
import { Carousel } from "react-bootstrap-v5";
import { BASE_URL, GET_SLIDERSETTINGS } from "../../constant/constants";

const Hero = () => {
  const [contents, setContents] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${GET_SLIDERSETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.homepage_sliders);
      })
      .catch(() => {});
  }, []);

  return (
    <Carousel fade>
      {contents.map((n) => (
        <Carousel.Item key={n.id}>
          <img
            className='d-block w-100'
            loading='lazy'
            style={{
              filter: "brightness(90%)",
              // marginTop: window.innerWidth <= 767 ? "120px" : "85px",
              height: window.innerWidth <= 767 ? "fit-content" : "500px",
            }}
            // height={500}
            src={`${BASE_URL}${n.image}`}
            alt={n.title}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Hero;
