import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";

import { BASE_URL, GET_IMAGE_BY_MENU_ID } from "../../constant/constants";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

const Gallery = (props) => {
  const [contentsImage, setContentsImage] = useState([]);

  const { id } = useParams();

  //for content images
  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id, props.id]);

  const images = Object.entries(contentsImage).map(([key, value]) =>
    key === "Gallery" ? value.map((n) => `${BASE_URL}/media/${n}`) : " "
  );

  // for image viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <ImageList
        md={{ width: 500, height: 400 }}
        sx={{ width: 200, height: 300 }}
        cols={3}
        // rowHeight={500}
        style={{ margin: "50px" }}
      >
        {images.map((item, index) =>
          item.map((item, index) => (
            <ImageListItem key={item.img} gap={8}>
              <img
                src={`${item}`}
                srcSet={`${item}`}
                alt=''
                className='rounded shadow-lg p-3 mb-5 bg-light rounded'
                onClick={() => openImageViewer(index)}
                loading='lazy'
              />
            </ImageListItem>
          ))
        )}
      </ImageList>

      {isViewerOpen &&
        images.map((n) => (
          <ImageViewer
            src={n}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        ))}
    </>
  );
};

export default Gallery;
