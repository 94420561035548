/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Scrollbar from "../../components/scrollbar";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import countryCodes from "../../api/countrycodes";
import {
  BASE_URL,
  CHECK_PRIMARY_PHONE,
  CHECK_REFFER_ID,
  PAYMENT_MATHODS_BY_TYPE,
} from "../../constant/constants";
import { getMember, saveMember } from "../../dataSlice/dataSlice";
import { useDispatch, useSelector } from "react-redux";

const Form = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const member = useSelector((state) => state.data.member);

  const [paymentType, setPaymentType] = useState({ payment: "mobile" });
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [imageFile, setImageFile] = useState();
  const [referId, setReferId] = useState(member?.refer_id);
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  const user_id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getMember(user_id));
  }, [dispatch, user_id]);
  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      country_code: selectedCountry.value,
      country: selectedCountry.label,
    });
  };

  const notify = () =>
    toast.success(
      `Welcome to our company! Complete your payment within 24 hour or your account will be deactivate.`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  const history = useHistory();

  const SubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);
    setLoading(true);

    const member = {
      first_name: data.first_name,
      email: data.email,
      father_name: data.father_name,
      mother_name: data.mother_name,
      username: data.username,
      refer_id: referId?.toUpperCase(),
      address: data.address,
      nid_no: data.nid_no,
      amount: 5100,
      payment_method: data.payment_method,
      payment_number: data.payment_number,
      password: data.password,
      confirmPassword: data.password,
      country_code1: selectedCountry.value,
      primary_phone: `${data.primary_phone}`,
      is_active: true,
      image: imageFile,
      is_admin: false,
      active: false,
    };
    dispatch(saveMember(member)).then((res) => {
      if (res.payload) {
        notify();
        setLoading(false);

        setTimeout(() => {
          jwt_access_token?.length > 0
            ? history.push("/dashboard")
            : history.push("/login");
        }, 1000);
      }
    });
  };

  //for content
  useEffect(() => {
    fetch(`${PAYMENT_MATHODS_BY_TYPE}${paymentType.payment}`)
      .then((response) => response.json())
      .then((data) => {
        setPaymentMethod(data.payment_method);
      })
      .catch(() => {});
  }, [paymentType.payment]);
  // Check Duplicate Phone Number
  useEffect(() => {
    fetch(
      `${CHECK_PRIMARY_PHONE}?primary_phone=${data?.country_code}${data?.primary_phone}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.primary_phone_exists) {
          toast.error(`Phone Number Already Exists,Please Provide New Number`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          const getValue = document.getElementById("primary_phone");
          getValue.value = "";
        }
      });
  }, [data.country_code, data.primary_phone]);

  // Check existance Reffer Id
  useEffect(() => {
    if (data?.head_user?.length == 7) {
      fetch(`${CHECK_REFFER_ID}?refer_id=${data?.head_user?.toUpperCase()}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.refer_id_exists) {
            toast.error(
              `Invalid Refer Id,Please Provide the Correct Refer Id`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            const getValue = document.getElementById("head_user");
            setTimeout(() => {
              getValue.value = "";
            }, 1000);
            // getValue.value = "";
          }
        });
    }
  }, [data.head_user]);

  const handleCountryChange = (event) => {
    setSelectedCountry(
      countryCodes.find((country) => country.label === event.target.value)
    );
  };

  function updateImageData(event) {
    setImageFile(event.target.files[0]);
  }

  return (
    <>
      <Fragment>
        <div
          className=' w-50 h-50'
          style={{
            position: "fixed",
            top: "25%",
            left: "43%",
            zIndex: 100,
            display: loading ? "block" : "none",
          }}>
          <img
            width='200'
            src='/loader.gif'
            loading='lazy'
            alt='logo'
            className='text-center'
            // style={{ display: loading ? "block" : "none" }}
          />
        </div>
        <div className='wpo-donation-page-area section-padding'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <div className='row justify-content-center'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-12'>
                      <div className='wpo-section-title'>
                        <h1 className='mt-2'>Bus Ticket Booking</h1>

                        <h5>
                          Address: Dhantalia, Chatalpar, Nasirnagor,
                          Bhramanbaria
                        </h5>
                        <h3 className='p-3 border mt-4'>
                          Donor Membership Form
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div id='Donations' className='tab-pane'>
                  <form onSubmit={SubmitHandler}>
                    <div className='wpo-donations-amount'>
                      <h2>Your Donation</h2>
                      <input
                        type='text'
                        className='form-control'
                        name='amount'
                        id='amount'
                        // onChange={updateData}
                        value='5100'
                        placeholder='Enter Donation Amount'
                      />
                    </div>
                    <div className='wpo-donations-details'>
                      <h2>Details</h2>
                      <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='first_name'
                            onChange={updateData}
                            id='first_name'
                            placeholder=' Name'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='username'
                            onChange={updateData}
                            id='username'
                            placeholder=' User Name'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix'>
                          <input
                            type='email'
                            className='form-control'
                            name='email'
                            id='email'
                            onChange={updateData}
                            placeholder='Email'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='head_user'
                            // onChange={updateData}
                            onChange={updateData}
                            onMouseOut={(e) => setReferId(e.target.value)}
                            defaultValue={member?.refer_id}
                            id='head_user'
                            placeholder=' Refer Id'
                            maxLength={7}
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='father_name'
                            onChange={updateData}
                            id='father_name'
                            placeholder='Father Name'
                          />
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='mother_name'
                            onChange={updateData}
                            id='mother_name'
                            placeholder='Mother Name'
                          />
                        </div>

                        <div className='col-lg-12 col-12 col-sm-12 form-group d-md-flex '>
                          <div className='col-md-4 col-12 col-sm-2 form-group'>
                            <select
                              className='form-control'
                              style={{ padding: "12px" }}
                              id='country'
                              value={selectedCountry.label}
                              onChange={handleCountryChange}>
                              {countryCodes.map((country) => (
                                <option
                                  key={country.label}
                                  value={country.label}>
                                  {country.label} ({country.code})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='col-md-2 col-sm-2 col-12 form-group'>
                            {" "}
                            <input
                              type='text'
                              className='form-control'
                              name='country_code'
                              id='country_code'
                              onChange={updateData}
                              value={selectedCountry.value}
                            />
                          </div>
                          <div className='col-md-6 col-12 col-sm-8 form-group'>
                            {" "}
                            <input
                              type='text'
                              className='form-control'
                              name='primary_phone'
                              id='primary_phone'
                              onChange={updateData}
                              placeholder='1912345678'
                              maxLength={10}
                            />
                          </div>
                        </div>

                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='nid_no'
                            onChange={updateData}
                            id='nid_no'
                            placeholder='NID No'
                            maxLength={10}
                          />
                        </div>

                        <div className='col-lg-12 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='address'
                            id='address'
                            onChange={updateData}
                            placeholder='Permanent Address'
                          />
                        </div>
                        <div className='col-lg-12 col-12 form-group'>
                          <input
                            type='password'
                            className='form-control'
                            name='password'
                            id='password'
                            onChange={updateData}
                            placeholder='Password'
                          />
                        </div>
                        <div className='col-lg-12 col-12 form-group'>
                          <input
                            type='file'
                            className='form-control'
                            name='image'
                            id='image'
                            onChange={updateImageData}
                            placeholder='Image'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='wpo-doanation-payment'>
                      <h2>Choose Your Payment Method</h2>

                      <p className='text-danger'>Bkash : 01740963098 </p>
                      <p className='text-danger'>Nogod : 01740963098  </p>
                      <div className='wpo-payment-area'>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='wpo-payment-option' id='open4'>
                              {/* <div className="wpo-payment-select">
                              <ul>
                                <li className="addToggle">
                                  <input
                                    id="card"
                                    type="radio"
                                    name="payment"
                                    value="card"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="card">Payment By Card</label>
                                </li>
                                <li className="addToggle">
                                  <input
                                    id="mobile"
                                    type="radio"
                                    name="payment"
                                    value="mobile"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="mobile">
                                    Payment By Mobile Banking
                                  </label>
                                </li>
                                <li className="addToggle">
                                  <input
                                    id="offline"
                                    type="radio"
                                    name="payment"
                                    value="offline"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="offline">
                                    Offline Payment
                                  </label>
                                </li>
                              </ul>
                            </div> */}
                              <div id='open5' className='payment-name'>
                                <ul>
                                  {paymentMethod.map((paymentData) => (
                                    <li className='visa'>
                                      <input
                                        id={paymentData.id}
                                        type='radio'
                                        name='payment_method'
                                        value={paymentData.id}
                                        onChange={updateData}
                                      />
                                      <label htmlFor={paymentData.id}>
                                        <img
                                          src={`${BASE_URL}${paymentData.image}`}
                                          alt=''
                                          height='40'
                                          width='78'
                                          loading='lazy'
                                        />
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                                <div
                                  className='contact-form form-style'
                                  style={{
                                    display:
                                      paymentType?.payment === "card"
                                        ? "block"
                                        : "none",
                                  }}>
                                  <div className='row'>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                      <label>Card holder Name</label>
                                      <input
                                        type='text'
                                        placeholder=''
                                        name='card_holder'
                                        id='card_holder'
                                        onChange={updateData}
                                      />
                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                      <label>Card Number</label>
                                      <input
                                        type='text'
                                        placeholder=''
                                        id='card_number'
                                        name='card_number'
                                        maxlength='16'
                                        onChange={updateData}
                                      />
                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                      <label>CVV</label>
                                      <input
                                        type='text'
                                        placeholder=''
                                        name='cvc_code'
                                        id='cvc_code'
                                        maxlength='4'
                                        onChange={updateData}
                                      />
                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                      <label>Expire Date</label>
                                      <input
                                        type='text'
                                        placeholder=''
                                        name='expiry_date'
                                        id='expiry_date'
                                        onChange={updateData}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='contact-form form-style'
                                  style={{
                                    display:
                                      paymentType?.payment === "mobile"
                                        ? "block"
                                        : "none",
                                  }}>
                                  <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                      <label>Payment Number</label>
                                      <input
                                        type='text'
                                        placeholder=''
                                        name='payment_number'
                                        id='payment_number'
                                        maxLength={11}
                                        onChange={updateData}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className='contact-form form-style'
                                  style={{
                                    display:
                                      paymentType?.payment === "offline"
                                        ? "block"
                                        : "none",
                                  }}>
                                  <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-12'></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row justify-content-center p-2  text'>
                        <small>
                          {" "}
                          আমি নিম্ন স্বাক্ষরকারী উপরে উল্লেখিত প্রতিষ্ঠানের একজন
                          দাতা সদস্য হিসেবে পবিএ কুরআনুল কারী শিক্ষা দেওয়ার জন্য
                          সম্পূর্ন জনকল্যাণ বা পরকালে সাওয়াব পাওয়ার আশায় উপরে
                          উল্লেখিত পরিমান টাকা সম্পূর্ন অনুদান হিসেবে স্বেচ্ছায়
                          ও স্বজ্ঞানে দান করিলাম । যাহা সম্পূর্ন অফেরৎ যোগ্য।
                        </small>
                      </div>
                    </div>

                    <div className='submit-area'>
                      <button type='submit' className='theme-btn submit-btn'>
                        Donate Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        <Scrollbar />
      </Fragment>
    </>
  );
};
export default Form;
