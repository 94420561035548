/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import {
  getAllsupportDepartments,
  getAllsupportPrioritys,
  getMember,
  getTickets,
  saveTicket,
} from "../../dataSlice/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SupportForm = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState();
  const [supportPriority, setSelectedSupportPriority] = useState(3);
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  const user_id = localStorage.getItem("user_id");
  const member = useSelector((state) => state.data.member);
  const supportPrioritys = useSelector((state) => state.data.supportPrioritys);
  const supportDepartments = useSelector(
    (state) => state.data.supportDepartments
  );

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const history = useHistory();

  const SubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);

    const mofiedData = {
      name: member.first_name,
      email: member.email,
      message: data.message,
      subject: data.subject,
      ticket_department: 1,
      ticket_priority: supportPriority,
      image: imageFile,
    };
    dispatch(saveTicket(mofiedData)).then((res) => {
      if (res.payload) {
        toast.success(
          `Thanks for contacting us! We will be in touch with you shortly.`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        document.forms["form-name"].reset();
        dispatch(getTickets(user_id));
      }
    });
  };

  //for content
  useEffect(() => {
    dispatch(getMember());
    dispatch(getAllsupportDepartments());
    dispatch(getAllsupportPrioritys());
  }, [dispatch]);

  function updateImageData(event) {
    setImageFile(event.target.files[0]);
  }

  const handleSupportPriorityChange = (event) => {
    setSelectedSupportPriority(event.target.value);
  };

  return (
    <div className='wpo-donation-page-area section-padding'>
      <div className='container'>
        <div className='row justify-content-center col-sm-12 col-md-10 mt-5 border shadow p-3 mb-5 bg-white rounded mx-auto'>
          <div className='col-lg-12'>
            <div id='Donations' className='tab-pane'>
              <form onSubmit={SubmitHandler} name='form-name'>
                <div className='wpo-donations-details'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                      <input
                        type='text'
                        className='form-control'
                        name='first_name'
                        onChange={updateData}
                        id='first_name'
                        value={member?.first_name}
                        placeholder=' Name'
                      />
                    </div>

                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix'>
                      <input
                        type='email'
                        className='form-control'
                        name='email'
                        id='email'
                        value={member?.email}
                        onChange={updateData}
                        placeholder='Email'
                      />
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                      <input
                        type='text'
                        className='form-control'
                        name='subject'
                        onChange={updateData}
                        id='subject'
                        placeholder='Subject'
                      />
                    </div>
                    <div className='col-lg-12 col-12 col-sm-12 form-group d-md-flex mb-5'>
                      <select
                        className='form-control'
                        style={{ padding: "12px" }}
                        id='priority'
                        // value={supportPriority.id}
                        onChange={handleSupportPriorityChange}>
                        {supportPrioritys?.map((supportPriority) => (
                          <option
                            key={supportPriority.id}
                            value={supportPriority.id}>
                            {/* {supportPriority.name} Priority */}
                            {supportPriority?.name.charAt(0).toUpperCase() +
                              supportPriority?.name.substr(1) || ""}{" "}
                            Priority
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
                      {" "}
                      <textarea
                        type='text'
                        className='form-control'
                        name='message'
                        onChange={updateData}
                        id='message'
                        placeholder='Message'
                        // maxLength={7}
                        rows='4'
                      />
                    </div>

                    <div className='col-lg-12 col-12 form-group'>
                      <input
                        type='file'
                        className='form-control'
                        name='image'
                        id='image'
                        onChange={updateImageData}
                        placeholder='Image'
                      />
                    </div>
                  </div>
                </div>

                <div className='submit-area'>
                  <button type='submit' className='theme-btn submit-btn'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportForm;
