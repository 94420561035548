/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTickets } from "../../dataSlice/dataSlice";
import SupportForm from "./SupportForm";
import { format } from "date-fns";
import SupportChat from "../SupportChat";
import { GET_TICKET_BY_ALL_USER_ID } from "../../constant/constants";

const Support = (props) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState("SupportList");
  const [toggle1, setToggle1] = useState("SupportList");
  const [adminTicket, setAdminTicket] = useState([]);
  const usertickets = useSelector((state) => state.data.ticket);
  const user_id = localStorage.getItem("user_id");
  useEffect(() => {
    dispatch(getTickets(user_id));
    fetch(`${GET_TICKET_BY_ALL_USER_ID}2`)
      .then((res) => res?.json())
      .then((data) => setAdminTicket(data?.tickets));
  }, [dispatch, user_id]);
  const tickets = adminTicket?.concat(usertickets);

  return (
    <div className='container'>
      <div
        className='row mt-4'
        style={{ display: toggle === "SupportList" ? "flex" : "none" }}>
        <h2 className='col-md-6'>
          <i className='fa-solid fa-headset text-center ms-5'></i> Support
          Ticket
        </h2>
        <span className='col-md-4'></span>
        <button
          className='btn rounded-pill btn-danger col-md-2 '
          onClick={() => {
            setToggle("SupportForm");
          }}>
          Open Ticket{" "}
        </button>
      </div>
      <div
        className='row mt-4'
        style={{ display: toggle === "SupportForm" ? "flex" : "none" }}>
        <h2 className='col-md-6'>
          <i className='fa-solid fa-headset text-center ms-5'></i> Open Ticket
        </h2>
        <span className='col-md-3'></span>
        <button
          className='btn rounded-pill btn-danger col-md-3'
          onClick={() => {
            setToggle("SupportList");
          }}>
          Back To Support Ticket List
        </button>
      </div>
      <div
        className='row mt-4'
        style={{ display: toggle === "SupportChat" ? "flex" : "none" }}>
        <h2 className='col-md-6'>
          <i className='fa-solid fa-headset text-center ms-5'></i> Support
          Ticket
        </h2>
        <span className='col-md-3'></span>
        <button
          className='btn rounded-pill btn-danger col-md-3'
          onClick={() => {
            setToggle("SupportList");
          }}>
          Back To Support Ticket List
        </button>
      </div>

      <div>
        <div
          className='row col-md-12'
          style={{ display: toggle === "SupportForm" ? "block" : "none" }}>
          <SupportForm></SupportForm>
        </div>

        <div
          style={{
            display: toggle === "SupportList" ? "flex" : "none",
          }}
          className='text-center justify-content-center w-100'>
          <div
            className='table-responsive mt-5'
            style={{ height: "500px", minWidth: "-webkit-fill-available" }}>
            <table className='table table-striped text-center'>
              <thead
                style={{ position: "sticky", top: 0 }}
                className='bg-dark text-white'>
                <tr style={{ height: "3rem", whiteSpace: "nowrap" }}>
                  <th scope='col'>Subject</th>
                  <th scope='col'>Date</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Priority</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {tickets?.map((ticket) => (
                  <tr
                    key={ticket.id}
                    style={{ height: "3rem", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setToggle1(ticket?.id);
                      setToggle("SupportChat");
                    }}>
                    <th>{ticket?.subject}</th>
                    <th>
                      {format(new Date(ticket?.created_at), "MMM dd, yyyy")}
                    </th>
                    <th>
                      {ticket?.ticket_type === "notice" ? "Notice" : "Support"}
                    </th>

                    <th>
                      <button
                        className='btn rounded-pill text-white'
                        style={{
                          backgroundColor:
                            ticket?.ticket_priority?.name === ("high" || "High")
                              ? "red"
                              : ticket?.ticket_priority?.name ===
                                ("medium" || "Medium")
                              ? "green"
                              : ticket?.ticket_priority?.name ===
                                ("low" || "Low")
                              ? "blue"
                              : ticket?.ticket_priority?.name
                              ? "black"
                              : "",
                          visibility:
                            ticket?.ticket_type === "notice"
                              ? "hidden"
                              : "visible",
                        }}>
                        {/* <b>{ticket?.ticket_priority?.name}</b> */}
                        <b>
                          {" "}
                          {ticket?.ticket_priority?.name
                            .charAt(0)
                            .toUpperCase() +
                            ticket?.ticket_priority?.name.substr(1) || ""}
                        </b>
                      </button>
                    </th>
                    <th>
                      <button
                        className='btn rounded-pill text-white'
                        style={{
                          backgroundColor:
                            ticket?.ticket_status?.name === ("open" || "Open")
                              ? "green"
                              : ticket?.ticket_status?.name ===
                                ("closed" || "Closed")
                              ? "red"
                              : ticket?.ticket_status?.name
                              ? "blue"
                              : "",
                          visibility:
                            ticket?.ticket_type === "notice"
                              ? "hidden"
                              : "visible",
                        }}>
                        {/* <b>{ticket?.ticket_status?.name}</b> */}
                        <b>
                          {ticket?.ticket_status?.name.charAt(0).toUpperCase() +
                            ticket?.ticket_status?.name.substr(1) || ""}
                        </b>
                      </button>
                    </th>
                    <th
                      onClick={() => {
                        setToggle1(ticket?.id);
                        setToggle("SupportChat");
                      }}>
                      {/* <i className='fa-solid fa-arrow-right'></i> */}
                      <i className='fa-solid fa-headset'></i>{" "}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ display: toggle === "SupportChat" ? "block" : "none" }}>
          <SupportChat id={toggle1}></SupportChat>
        </div>
      </div>
    </div>
  );
};
export default Support;
