import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from "../../constant/constants";
import shape from "../../images/ab-shape-2.png";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const AboutS2 = (props) => {
  // const { id } = useParams();
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  //for content images
  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [props.id]);

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});
  }, [props.id]);
  const newContents = items(contents);

  return (
    <section
      className='wpo-about-section section-padding'
      style={{ visibility: contents ? "visible" : "hidden" }}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-about-wrap'>
              {Object.entries(contentsImage ? contentsImage : {}).map(
                ([key, value]) =>
                  key === "About" ? (
                    <div className='wpo-about-img'>
                      <img
                        loading='lazy'
                        style={{ height: "300px" }}
                        src={`${BASE_URL}/media/${value}`}
                        alt=''
                      />
                      <div className='wpo-ab-shape-1'>
                        <div className='s-s1'></div>
                        <div className='s-s2'></div>
                      </div>
                      <div className='wpo-ab-shape-2'>
                        <img src={shape} alt='' loading='lazy' />
                      </div>
                    </div>
                  ) : (
                    " "
                  )
              )}
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='wpo-about-text px-2'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "About" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                        className='text-justify'
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutS2;
