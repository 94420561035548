/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import erimg from "../../images/error-404.png";
import UserProfile from "../Dashboard/UserProfile";

const ProfileShow = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return <UserProfile></UserProfile>;
};

export default ProfileShow;
