import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useEffect } from "react";
import {
  GET_CAUSECONTENT_BY_CAUSE_ID,
  GET_CAUSE_BY_ID,
} from "../../constant/constants";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const CauseTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [data, setData] = useState({});

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const { id } = useParams();

  const [causes, setCauses] = useState();

  //for content images
  useEffect(() => {
    fetch(`${GET_CAUSE_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCauses(data);
      })
      .catch(() => {});
  }, [id, props.id]);

  const [contents, setContents] = useState("");

  useEffect(() => {
    fetch(`${GET_CAUSECONTENT_BY_CAUSE_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.cause_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  const newContents = items(contents);
  return (
    <div>
      <div className='wpo-case-details-tab '>
        <Nav tabs className='d-flex justify-content-around'>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Description
            </NavLink>
          </NavItem>
          <NavItem>
            {/* <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                // toggle("2");
              }}
              to={`/donate/${id}/${data?.name}`}
            >
             
            
            </NavLink> */}

            <Link
              // onClick={ClickHandler}
              className='e-btn pt-2'
              to={`/donate/${id}/${causes?.name}`}
            >
              <span> Donate Now</span>
            </Link>
          </NavItem>
          {/* <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >

                            Comments
                        </NavLink>
                    </NavItem> */}
        </Nav>
      </div>
      <div className='wpo-case-details-text'>
        <TabContent activeTab={activeTab}>
          <TabPane tabId='1'>
            <Row>
              <Col sm='12'>
                <div className='wpo-case-content'>
                  <div className='wpo-case-text-top'>
                    <h2>{causes?.name}</h2>
                    <div className='progress-sub'>
                      <div className='progress-section'>
                        <div className='process'>
                          <div className='progress'>
                            <div
                              className='progress-bar'
                              style={{ width: `${causes?.process}%` }}
                            >
                              <div className='progress-value'>
                                <span>{causes?.process}</span>%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <span>Raised:</span> {causes?.raised_amount}
                        </li>
                        <li>
                          <span>Goal:</span> {causes?.goal_amount}
                        </li>
                        <li>
                          <span>Donar:</span> {causes?.contributor}
                        </li>
                      </ul>
                    </div>
                    <div className='case-b-text'>
                      {newContents.map((service, index) =>
                        Object.entries(service).map(([key, value]) =>
                          key === "Description" ? (
                            <div className='' key={index}>
                              <Interweave
                                allowAttributes
                                allowElements
                                disableLineBreaks={true}
                                content={value}
                              />
                            </div>
                          ) : (
                            " "
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='2'>
            <Row>
              <Col sm='12'>
                <div id='Donations' className='tab-pane'>
                  <form onSubmit={SubmitHandler}>
                    <div className='wpo-donations-amount'>
                      <h2>Your Donation</h2>
                      <input
                        type='text'
                        className='form-control'
                        name='text'
                        id='text'
                        onChange={updateData}
                        placeholder='Enter Donation Amount'
                      />
                    </div>
                    <div className='wpo-donations-details'>
                      <h2>Details</h2>
                      <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='name'
                            id='fname'
                            onChange={updateData}
                            placeholder='First Name'
                          />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='name'
                            id='name'
                            onChange={updateData}
                            placeholder='Last Name'
                          />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix'>
                          <input
                            type='email'
                            onChange={updateData}
                            className='form-control'
                            name='email'
                            id='email'
                            placeholder='Email'
                          />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12 form-group'>
                          <input
                            type='text'
                            className='form-control'
                            name='Adress'
                            id='Adress'
                            onChange={updateData}
                            placeholder='Adress'
                          />
                        </div>
                        <div className='col-lg-12 col-12 form-group'>
                          <textarea
                            className='form-control'
                            name='note'
                            id='note'
                            onChange={updateData}
                            placeholder='Message'
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className='wpo-doanation-payment'>
                      <h2>Choose Your Payment Method</h2>
                      <div className='wpo-payment-area'>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='wpo-payment-option' id='open4'>
                              <div className='wpo-payment-select'>
                                <ul>
                                  <li className='addToggle'>
                                    <input
                                      id='add'
                                      type='radio'
                                      name='payment'
                                      value='30'
                                    />
                                    <label htmlFor='add'>Payment By Card</label>
                                  </li>
                                  <li className='removeToggle'>
                                    <input
                                      id='remove'
                                      type='radio'
                                      name='payment'
                                      value='30'
                                    />
                                    <label htmlFor='remove'>
                                      Offline Donation
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='submit-area'>
                      <button type='submit' className='theme-btn submit-btn'>
                        Donate Now
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId='3'>
            <Row>
              <Col sm='12'>
                <div id='Comments' className='tab-pane wpo-blog-single-section'>
                  <div className='comments-area'>
                    <div className='comments-section'>
                      <h3 className='comments-title'>Comments</h3>
                      <ol className='comments'>
                        <li
                          className='comment even thread-even depth-1'
                          id='comment-1'
                        >
                          {/* <div id="div-comment-1">
                            <div className="comment-theme">
                              <div className="comment-image">
                                <img src={cmt1} alt="" />
                              </div>
                            </div>
                            <div className="comment-main-area">
                              <div className="comment-wrapper">
                                <div className="comments-meta">
                                  <h4>
                                    John Abraham{" "}
                                    <span className="comments-date">
                                      Octobor 28,2021 At 9.00am
                                    </span>
                                  </h4>
                                </div>
                                <div className="comment-area">
                                  <p>
                                    I will give you a complete account of the
                                    system, and expound the actual teachings of
                                    the great explorer of the truth,{" "}
                                  </p>
                                  <div className="comments-reply">
                                    <Link
                                      className="comment-reply-link"
                                      to="/cause-single/1"
                                    >
                                      <i
                                        className="fa fa-reply"
                                        aria-hidden="true"
                                      ></i>
                                      <span> Reply</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <ul className='children'>
                            <li className='comment'>
                              <div>
                                <div className='comment-theme'>
                                  {/* <div className="comment-image">
                                    <img src={cmt2} alt="" />
                                  </div> */}
                                </div>
                                <div className='comment-main-area'>
                                  <div className='comment-wrapper'>
                                    <div className='comments-meta'>
                                      <h4>
                                        Lily Watson{" "}
                                        <span className='comments-date'>
                                          Octobor 28,2021 At 9.00am
                                        </span>
                                      </h4>
                                    </div>
                                    <div className='comment-area'>
                                      <p>
                                        I will give you a complete account of
                                        the system, and expound the actual
                                        teachings of the great explorer of the
                                        truth,{" "}
                                      </p>
                                      <div className='comments-reply'>
                                        <Link
                                          className='comment-reply-link'
                                          to='/cause-single/1'
                                        >
                                          <span>
                                            <i
                                              className='fa fa-reply'
                                              aria-hidden='true'
                                            ></i>{" "}
                                            Reply
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ul>
                                <li className='comment'>
                                  <div>
                                    <div className='comment-theme'>
                                      {/* <div className="comment-image">
                                        <img src={cmt3} alt="" />
                                      </div> */}
                                    </div>
                                    <div className='comment-main-area'>
                                      <div className='comment-wrapper'>
                                        <div className='comments-meta'>
                                          <h4>
                                            John Abraham{" "}
                                            <span className='comments-date'>
                                              Octobor 28,2021 At 9.00am
                                            </span>
                                          </h4>
                                        </div>
                                        <div className='comment-area'>
                                          <p>
                                            I will give you a complete account
                                            of the system, and expound the
                                            actual teachings of the great
                                            explorer of the truth,{" "}
                                          </p>
                                          <div className='comments-reply'>
                                            <Link
                                              className='comment-reply-link'
                                              to='/cause-single/1'
                                            >
                                              <span>
                                                <i
                                                  className='fa fa-reply'
                                                  aria-hidden='true'
                                                ></i>{" "}
                                                Reply
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className='comment-respond'>
                    <h3 className='comment-reply-title'>Leave a Comment</h3>
                    <form
                      method='post'
                      id='commentform'
                      className='comment-form'
                      onSubmit={SubmitHandler}
                    >
                      <div className='form-inputs'>
                        <input placeholder='Name' type='text' />
                        <input placeholder='Email' type='email' />
                        <input placeholder='Website' type='url' />
                      </div>
                      <div className='form-textarea'>
                        <textarea
                          id='comment'
                          placeholder='Write Your Comments...'
                        ></textarea>
                      </div>
                      <div className='form-submit'>
                        <input id='submit' value='Reply' type='submit' />
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default CauseTabs;
